import React, { useContext } from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import { ucFirst } from '../../utils/caseconverters';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import LazyLoad from 'react-lazyload';
import { TrackingContext } from '../StreamField/StreamField';
import { trackBanner, trackCtaClickStartpage } from '../../utils/datalayer';

import Byline from '../Byline';

const PictureImage = ({
    size,
    image: {
        renditions: { small, medium, large, smallWebp, mediumWebp },
        alt,
        focal,
    },
}) => (
    <picture className={styles['Card__Picture']}>
        {smallWebp?.src && (
            <source
                srcSet={smallWebp.src}
                media="(max-width: 639px)"
                type="image/webp"
            />
        )}
        {small?.src && <source srcSet={small.src} media="(max-width: 639px)" />}
        {mediumWebp?.src && (
            <source
                srcSet={mediumWebp.src}
                media="(min-width: 640px) and (max-width: 1200px)"
                type="image/webp"
            />
        )}
        <source
            srcSet={medium?.src}
            media="(min-width: 640px) and (max-width: 1200px)"
        />
        <source srcSet={large?.src} media="(min-width: 1200px)" />
        <img
            sizes="343px"
            width={size === 'xlarge' ? 486 : 253}
            height={size === 'xlarge' ? 288 : 312}
            srcSet={`${medium?.src} 600w, ${large.src} 1200w`}
            src={small?.src}
            alt={alt}
            style={{
                objectPosition: focal?.x + ' ' + focal?.y,
            }}
        />
    </picture>
);

const Card = ({
    href,
    title,
    image,
    category,
    quote,
    size,
    byline,
    modifiers,
    color,
    type,
    tabIndex,
    onCardClick,
}) => {
    const bannerLevel = useContext(TrackingContext);
    const hasByline = !!byline;

    return (
        <div
            className={classNames(
                modifiers.map((x) => ' ' + x),
                styles['Card'],
                styles['Card--' + ucFirst(size)],
                styles['Card--' + type],
                {
                    [styles['Card--WithByline']]: hasByline,
                    [styles['Card--' + color]]: color,
                }
            )}
        >
            <a
                className={styles['Card__Link']}
                href={href}
                tabIndex={tabIndex}
                onClick={() => {
                    onCardClick(title, category || type.toLowerCase())
                    if (bannerLevel) {
                        trackBanner(
                            bannerLevel,
                            category || type.toLowerCase(),
                            title
                        );
                    }
                }}
            >
                <span className="sr-only">{title}</span>
            </a>
            <div className={styles['Card__Wrapper']}>
                <LazyLoad once style={{ width: '100%', height: '100%' }}>
                    {image && image.renditions && (
                        <PictureImage size={size} image={image} />
                    )}
                </LazyLoad>

                <div className={styles['Card__Shadow']} />
                <div
                    className={classNames(styles['Card__Information'], {
                        [styles['Card__InformationByline']]: hasByline,
                        [styles['Card--HasQuote']]: quote,
                    })}
                >
                    {color !== 'White' && (
                        <h3
                            className={classNames(
                                styles['Card__InformationHeading'],
                                {
                                    [styles[
                                        'Card__InformationHeading--AddMargin'
                                    ]]: !category && !quote,
                                }
                            )}
                        >
                            {title && title}
                        </h3>
                    )}

                    {category && color !== 'White' && (
                        <p className={styles['Card__InformationCategory']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')} :
                            </span>
                            {category}
                        </p>
                    )}

                    {quote && color !== 'White' && (
                        <blockquote
                            className={styles['Card__InformationQuote']}
                        >
                            "{quote}"
                        </blockquote>
                    )}
                    {byline && (
                        <div className={styles['Card__BylineContainer']}>
                            <Byline
                                image={byline.image}
                                author={byline.author}
                                size="small"
                                modifiers={['Card']}
                            />
                        </div>
                    )}
                </div>
            </div>
            {color === 'White' && (
                <div className={styles['Card__Information-wrapper']}>
                    <h3 className={styles['Card__InformationHeading']}>
                        {title && title}
                    </h3>

                    {category && (
                        <p className={styles['Card__InformationCategory']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')} :
                            </span>
                            {category}
                        </p>
                    )}
                    {quote && (
                        <blockquote
                            className={styles['Card__InformationQuote']}
                        >
                            "{quote}"
                        </blockquote>
                    )}
                </div>
            )}
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    byline: PropTypes.object,
    quote: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'full']),
    modifiers: PropTypes.array,
    color: PropTypes.string,
    type: PropTypes.string,
    onCardClick: PropTypes.func,
};

Card.defaultProps = {
    title: '',
    image: null,
    byline: null,
    quote: null,
    size: 'medium',
    modifiers: [],
    color: '',
    type: '',
    onCardClick: () => null,
};

export default Card;
